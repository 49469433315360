import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Layout from '../components/layout/LayoutInner';
import Seo from '../components/layout/SEO';
import InnerHead from '../components/layout/InnerHead';
import Testimonials from '../components/home/Testimonials';
import { graphql } from 'gatsby';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    const thisValue = e.target.value === 'checkbox' ? e.target.checked : e.target.value;
    setValue(thisValue)
  }
  return {
    value,
    onChange: handleChange,
  }  
}

export default function ContactUs({ data }) {
  // form names
  const name = useFormInput('');
  const phone = useFormInput('');
  const email = useFormInput('');
  const newClient = useFormInput('');
  const website = useFormInput('');
  const message = useFormInput('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [notification, setNotification] = useState('')

  // to stringify values
  const nameVal = name.value;
  const phoneVal = phone.value;
  const emailVal = email.value;
  const newClientVal = newClient.value;
  const websiteVal = website.value;
  const messageVal = message.value;

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!executeRecaptcha) {
      return
    }

    // form field validations
    if (!name.value) {
      return setNotification(`Please provide your name.`);
    } else if (!phone.value) {
      return setNotification(`Please provide your phone number.`);
    } else if (!email.value) {
      return setNotification(`Please provide your email address.`);
    } else if (!message.value) {
      return setNotification(`Please tell us a little about your case.`)
    }
    const token = await executeRecaptcha('contactUs');

    const data = JSON.stringify({
      nameVal,
      phoneVal,
      emailVal,
      newClientVal,
      websiteVal,
      messageVal,
      token
    })

    fetch('/api/v1/contact/contact-us', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        setNotification(data.msg)
      })
    
    setNotification('Data Sent')
  }

  return (
    <Layout>
      <Seo
        title='Contact Us'
        canonical='contact-us/'
      />
      <InnerHead title="Contact Dreyer Law" caption="When Results Matter" />
      <main className="contact-page">
        <div className="container">
          <p>
            At Dreyer Law, we provide personal, hands-on representation and maintain constant contact with our clients, something we have heard time and again most other law firms do not necessarily do on a consistent basis.
          </p>
          <div role="form" className="contactform--form" lang="en-US">
            <div className="screen-reader-response">
              <p role="status" aria-live="polite" aria-atomic="true"></p>
              <ul></ul>
            </div>
            <form onSubmit={handleSubmit} >
              <div className="reviewBlock">
                {notification && <span>{notification}</span>}
              </div>
              <div className="contactform--wrapper">
                <div className="column-30">
                  <span className="contactform--name">
                    <input type="text" name="name" id="nameContact" aria-required="true" aria-invalid="false" placeholder="Name*" {...name} required/>
                  </span>
                </div>
                <div className="column-30">
                  <span className="contactform--phone">
                    <input type="text" name="phone" id="phoneContact" aria-required="true" aria-invalid="false" placeholder="Phone*" {...phone} required/>
                  </span>
                </div>
                <div className="clear">
                  <input type="text" name="website" id="websiteContact" placeholder="Website" {...website} />
                </div>
                <div className="column-30">
                  <span className="contactform--newclient">
                    <select name="newClient" id="newClientContact" aria-invalid="false" defaultValue={"default"} {...newClient}>
                      <option value="default" disabled selected>Are you a new client?</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </span>
                </div>
                <div className="column-30">
                  <span className="contactform--email">
                    <input type="email" name="email" id="emailContact" aria-required="true" aria-invalid="false" placeholder="Email*" {...email} required />
                  </span>
                </div>
                <div className="clear"></div>
                <div className="column-60">
                  <span className="contactform--message">
                    <textarea name="message" id="contactFormMessage" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Message*" {...message} required></textarea>
                  </span>
                </div>
                <div className="clear"></div>
                <div className="column-60">
                  <input className="contactform--submit" type="submit" value="Submit Now" />
                  <span className="spinner"></span>
                </div>
                <div className="clear"></div>
              </div>
              <div className="contactform--response" aria-hidden="true">
              </div>
            </form>
          </div>
        </div>
      </main>
      <Testimonials data={data.allTestimonialsJson} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allTestimonialsJson(limit: 4) {
      edges {
        node {
          id
          author
          excerpt
        }
      }
    }
  }
`